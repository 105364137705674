import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { authActions } from 'redux/actions';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import {
  Colors,
  SvgWrapper,
  H1,
  Container,
  PrimaryButton,
  formValidateMessages,
  device,
  Modal,
  isMobile,
} from 'utils';
import { BaseLayout } from 'components';
import { ShortBackground, ContactImage } from 'images';
import { Input, Form, Select } from 'antd';
import ReCAPTCHA from 'react-google-recaptcha';
import { store } from 'react-notifications-component';
import { Helmet } from 'react-helmet';
import mailbox from '../../images/Mailbox.mp4';

const FormItem = Form.Item;
const { Option } = Select;

const receivers = [
  { name: 'Technischer Support', email: 'technik@commonsplace.de' },
  {
    name: 'Projektberatung & Unterstützung',
    email: 'projekte@commonsplace.de',
  },
  { name: 'Zahlungsangelegenheiten', email: 'salam@commonsplace.de' },
  { name: 'Allgemeine Anfragen', email: 'salam@commonsplace.de' },
  {
    name: 'Support für ein aktives Projekt',
    email: 'projekte@commonsplace.de',
  },
];

const fromProjectReceivers = [
  { name: 'Technische Probleme', email: 'technik@commonsplace.de' },
  {
    name: 'Projekt beenden',
    email: 'projekte@commonsplace.de',
  },
  {
    name: 'Auszahlung anfordern',
    email: 'projekte@commonsplace.de',
  },
  {
    name: 'Projektzeit verlängern (Tage hinzufügen)',
    email: 'projekte@commonsplace.de',
  },
  { name: 'Sonstiges', email: 'projekte@commonsplace.de' },
];
// const fromProjectReceivers = [
//   { name: 'Technisches Probleme', email: 'technik@commonsplace.de' },
//   {
//     name: 'Projekt beenden & auszahlen (3-5 Tage)',
//     email: 'projekte@commonsplace.de',
//   },
//   {
//     name: 'Projekt beenden & sofort auszahlen (zzgl. Gebühren)',
//     email: 'projekte@commonsplace.de',
//   },
//   { name: 'Projekt verlängern', email: 'projekte@commonsplace.de' },
//   { name: 'Sonstiges', email: 'projekte@commonsplace.de' },
// ];
const Intro = styled.div`
  text-align: center;
  h1 {
    font-size: 55px;
    @media ${device.allMobile} {
      font-size: 30px;
    }
  }
`;
const ModalWrapper = styled.div`
  .ant-select-selector,
  input,
  textarea {
    border: 1px solid ${Colors.secondaryColor} !important;
    border-radius: 15px !important;
  }
  textarea {
    min-height: 200px;
  }
  .ant-select-selector,
  input {
    height: 44px !important;
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
`;
const ContactUsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0px;
  margin-top: 150px;
  @media ${device.allMobile} {
    margin-top: 25px;
    flex-wrap: wrap;
  }
  .contact-form-image {
    justify-content: flex-start;
    padding-left: 50px;
  }
  .contact-form-image video {
    width: 80%;
    height: 520px;
    @media ${device.tablet} {
      width: 100%;
      height: auto;
      padding-left: 0px;
    }
    @media ${device.allMobile} {
      width: 100%;
      height: auto;
      padding-left: 0px;
    }
  }

  @media ${device.laptop} {
    align-items: center;
    margin-top: 100px;
  }
  @media ${device.tablet} {
    margin-top: 75px;
  }
  & > div {
    width: 50%;
    @media ${device.allMobile} {
      width: 100%;
      order: 2;
    }
    & + div {
      margin-top: -108px;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;
      @media ${device.allMobile} {
        margin-top: 25px;
        order: 1;
      }
      @media ${device.laptop} {
        margin-top: -165px;
      }
      @media ${device.tablet} {
        margin-top: -108px;
      }
    }
    .ant-select-selector,
    input,
    textarea {
      border: 1px solid ${Colors.secondaryColor} !important;
      border-radius: 15px !important;
    }
    textarea {
      min-height: 200px;
    }
    .ant-select-selector,
    input {
      height: 44px !important;
      display: flex;
      align-items: center;
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  @media ${device.allMobile} {
    flex-wrap: wrap;
  }
  & > div {
    width: 50%;
    @media ${device.allMobile} {
      width: 100%;
    }
  }

  .ant-form-item {
    .ant-form-item-control-input-content {
      padding-left: 0;
      padding-right: 10px;
      @media ${device.allMobile} {
        padding-right: 0;
      }
    }
    & + .ant-form-item {
      .ant-form-item-control-input-content {
        padding-right: 0;
        padding-left: 10px;
        @media ${device.allMobile} {
          padding-left: 0;
        }
      }
    }
  }
`;
const ContactUsHeader = styled.div`
  margin-top: 100px;
  h1 {
    @media ${device.allMobile} {
      font-size: 25px;
    }
  }
`;

export class ContactUsPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    sendContact: PropTypes.func.isRequired,
  };
  state = {
    captcha: null,
    label: '',
    showModal: false,
    visible: false,
  };
  onChange = (value) => {
    this.setState({ captcha: value });
  };
  onFinish = (values) => {
    const { captcha } = this.state;
    const { sendContact, closeModal, projectId, openProfileModal } = this.props;
    const data = {
      ...values,
      receiver: projectId
        ? fromProjectReceivers.find((v) => v.name === values.subject)?.email
        : receivers.find((v) => v.name === values.subject)?.email,
    };
    if (projectId) {
      data.project_id = projectId;
    }

    const notification = {
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated animate__fadeIn'], // `animate.css v4` classes
      animationOut: ['animate__animated animate__fadeOut'], // `animate.css v4` classes
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    };
    if (captcha) {
      values.subject === 'Support für ein aktives Projekt'
        ? openProfileModal
          ? openProfileModal()
          : this.setState({ showModal: true })
        : sendContact(data);

      closeModal();
    } else {
      store.addNotification({
        ...notification,
        title: 'Fehler!',
        type: 'danger',
        message: 'Bitte alle Felder ausfüllen.',
      });
    }
  };
  render() {
    const { formOnly, user, projectId, openProfileModal } = this.props;
    const { label, showModal, visible } = this.state;
    const form = (
      <>
        <div>
          <Form
            validateMessages={formValidateMessages}
            onFinish={this.onFinish}
          >
            <FormWrapper>
              <FormItem
                name="name"
                initialValue={
                  user ? user?.first_name + ' ' + user?.last_name : ''
                }
                rules={[
                  {
                    required: true,
                    message: 'Name ist erforderlich.',
                  },
                ]}
              >
                <Input placeholder="Name" />
              </FormItem>
              <FormItem
                name="email"
                initialValue={user?.email}
                rules={[
                  {
                    required: true,
                    message: 'Email ist erforderlich.',
                  },
                ]}
              >
                <Input type="email" placeholder="E-Mail-Adresse" />
              </FormItem>
            </FormWrapper>
            <FormWrapper>
              <FormItem name="telephone" initialValue={user?.phone_number}>
                <Input placeholder="Telefon" />
              </FormItem>
              <FormItem
                name="subject"
                rules={[
                  {
                    required: true,
                    message: 'Betreff ist erforderlich.',
                  },
                ]}
              >
                <Select
                  placeholder="Betreff"
                  onChange={(label) => {
                    if (label === 'Support für ein aktives Projekt') {
                      openProfileModal
                        ? openProfileModal()
                        : this.setState({ showModal: true });
                    }
                    this.setState({ label });
                  }}
                >
                  {projectId
                    ? fromProjectReceivers.map((r) => (
                        <Option value={r.name}>{r.name}</Option>
                      ))
                    : receivers.map((r) => (
                        <Option value={r.name}>{r.name}</Option>
                      ))}
                </Select>
                {/* <Input placeholder="Betreff" /> */}
              </FormItem>
            </FormWrapper>

            <FormItem
              name="body"
              rules={[
                {
                  required: fromProjectReceivers
                    .slice(1, 4)
                    .find((v) => v.name === label)
                    ? false
                    : true,
                  message: 'Eine Nachricht ist erforderlich.',
                },
              ]}
            >
              <Input.TextArea placeholder="Deine Nachricht" />
            </FormItem>
            <FormItem name="recaptcha">
              <ReCAPTCHA
                sitekey="6Le3SXcaAAAAALMCZbmfzc6AiJ1GpIHzs2XP61at"
                onChange={this.onChange}
              />
            </FormItem>
            <FormItem>
              <PrimaryButton
                type="primary"
                htmltype="submit"
                minWidth="150px"
                style={{ marginBottom: 0 }}
              >
                Senden
              </PrimaryButton>
            </FormItem>
          </Form>
        </div>
        {!formOnly && (
          <div className="contact-form-image">
            {/* <SvgWrapper width={'100%'} height={'auto'}> */}
            {/* <ContactUs /> */}
            {isMobile() ? (
              <img src={ContactImage} alt="" />
            ) : (
              <video
                src={mailbox}
                autoPlay
                muted
                playsinline
                webkit-playsinline
              />
            )}
            {/* </SvgWrapper> */}
          </div>
        )}
      </>
    );
    if (formOnly)
      return (
        <>
          <ModalWrapper>{form}</ModalWrapper>
        </>
      );
    return (
      <div>
        {showModal && (
          <ProfileModal
            handelCloseModal={() => this.setState({ showModal: false })}
          />
        )}
        <Helmet>
          <meta
            name="description"
            content="Kontakt zu commonsplace aufnehmen. Support-Team steht zur Verfügung, um Fragen zu beantworten und Hilfe zu leisten."
          />
        </Helmet>
        <BaseLayout
          maxHeightProps="500px"
          maxHeightMobileProps="250px"
          container={false}
          img={ShortBackground}
          bannerComponent={
            <Intro>
              <H1>Kontaktiere uns</H1>
            </Intro>
          }
        >
          <Container>
            <ContactUsHeader>
              <h2 style={{ fontSize: 32 }}>
                Du hast eine Frage oder ein spezielles Anliegen?
              </h2>
              <p style={{ fontSize: 30 }}>
                commonsplace sollte einfach und unkompliziert sein, aber wir
                verstehen, dass manchmal Fragen und Probleme auftreten. Bitte
                schaue zunächst bei den <Link to="faqs">FAQs </Link> nach, ob
                deine Frage nicht dort schon beantwortet wurde.
              </p>
              <p style={{ fontSize: 30 }}>
                Wenn du uns kontaktieren möchtest, nutze bitte das untenstehende
                Formular. Wir antworten dir so schnell wie möglich.
              </p>
              <p style={{ fontSize: 30 }}>
                Wir freuen uns über deine Nachricht.
              </p>
            </ContactUsHeader>
            <ContactUsWrapper>{form}</ContactUsWrapper>
          </Container>
        </BaseLayout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  sendContact: (form) => dispatch(authActions.sendContact(form)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);

export const ProfileModal = ({ handelCloseModal }) => {
  const { push } = useHistory();
  return (
    <Modal
      title={
        <div style={{ padding: 20 }}>
          So erhältst du personalisierten Support für dein aktives Projekt:
        </div>
      }
      showModal={true}
      handelCloseModal={handelCloseModal}
    >
      <div>
        <ul style={{ listStyleType: 'decimal' }}>
          <li>
            Melde dich an und besuche dein Profil über den Menü-Button{' '}
            <Link to="/profile" onClick={handelCloseModal}>
              <b style={{ textDecoration: 'underline' }}>"Mein Profil"</b>
            </Link>
          </li>
          <li>
            Wähle das von dir gestartete Projekt aus, für das du Support
            benötigst.
          </li>
          <li>
            Klicke auf den Button <b>"Support anfragen"</b>, um personalisierten
            Support zu erhalten!
          </li>
        </ul>
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <PrimaryButton
            onClick={() => {
              handelCloseModal();
              push('/profile');
            }}
          >
            Besuche dein Profil
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  );
};
