import React, { Component } from 'react';
import { Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { H1, PrimaryButton } from 'utils';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { authActions } from 'redux/actions';
import isEmpty from 'loadsh/isEmpty';
import { Redirect } from 'react-router-dom';
import { formValidateMessages } from 'utils';
import { GoogleLogin } from '@react-oauth/google';
const FormItem = Form.Item;

class SingUpForm extends Component {
  static propTypes = {
    handleCurrentFormState: PropTypes.func,
    loading: PropTypes.bool,
    errors: PropTypes.array,
    register: PropTypes.func.isRequired,
    user: PropTypes.object,
    singUpSuccess: PropTypes.bool,
    handelCloseModal: PropTypes.func,
  };

  onFinish = (values) => {
    const { register } = this.props;
    register(values);
  };

  componentDidUpdate(prevProps) {
    const { singUpSuccess, handelCloseModal } = this.props;
    if (singUpSuccess && !prevProps.singUpSuccess) {
      handelCloseModal && handelCloseModal();
    }
  }

  handleSuccess = (response) => {
    const token = response.credential;
    this.props.loginWithGoogle({ token });
    this.setState({ redirectToProfile: true });
  };

  handleError = () => {
    console.log('Google login failed');
  };

  render() {
    const {
      handleCurrentFormState,
      loading,
      user,
      singUpSuccess,
      handelCloseModal,
      setDoPayment,
      navigate,
      login,
    } = this.props;
    const userToken = localStorage.getItem('access-token');
    const client = localStorage.getItem('client');
    const isLoggedIn = !isEmpty(user) && userToken && client;
    // if (singUpSuccess && navigate === false) {
    //   handelCloseModal();
    // }
    return (
      <Spin spinning={loading}>
        {singUpSuccess && navigate === undefined && (
          <Redirect to={{ pathname: '/welcome' }} />
        )}
        {isLoggedIn && <Redirect to={{ pathname: '/' }} />}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <H1 style={{ margin: 0 }}>Registrieren</H1>
          <GoogleLogin
            onSuccess={this.handleSuccess}
            onError={this.handleError}
          />
        </div>
        <Form
          name="sing-up"
          validateMessages={formValidateMessages}
          onFinish={this.onFinish}
        >
          <FormItem
            name="firstName"
            label="Vorname"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name="lastName"
            label="Nachname"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </FormItem>
          <FormItem
            name="email"
            label="E-Mail-Adresse"
            rules={[
              {
                required: true,
                message: 'Bitte gib deine E-Mail-Adresse ein.',
              },
            ]}
          >
            <Input type="email" />
          </FormItem>
          <FormItem
            name="password"
            label="Passwort"
            rules={[
              {
                required: true,
                message: 'Bitte gib dein Passwort ein.',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </FormItem>

          <FormItem
            name="passwordConfirmation"
            label="Passwort wiederholen"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Bitte bestätigte dein Passwort!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    'Die eingegebenen Passwörter stimmen nicht überein!'
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </FormItem>

          <FormItem>
            <PrimaryButton htmltype="submit">Anmelden</PrimaryButton>
          </FormItem>
        </Form>
        <div>
          <p>
            Bereits registriert?{' '}
            <Link to="#!" onClick={() => handleCurrentFormState('login')}>
              Jetzt anmelden!
            </Link>
          </p>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, loading, errors, singUpSuccess } = state.authentication;
  return {
    user,
    loading,
    errors,
    singUpSuccess,
  };
};

const mapDispatchToProps = (dispatch) => ({
  register: (user) => dispatch(authActions.register(user)),
  loginWithGoogle: (user) => dispatch(authActions.loginWithGoogle(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SingUpForm);
