import React from 'react';
import { ProgressBar } from 'components';
import { TimeIcon, TargetIcon, SupporterIcon } from 'images';
import {
  numberWithCommas,
  Colors,
  SvgWrapper,
  device,
  isTablet,
  isLabTop,
  isMobile,
} from 'utils';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import DonationButton from 'utils/DonationButton';

const CardWrapper = styled.article`
  background: ${Colors.white};
  border-radius: 10px;
  // border: 2px solid ${Colors.secondaryColor};
  cursor: pointer;
  width: 100%;
  margin: auto;
  height: 100%;
  position: relative;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  @media ${device.laptop} {
    width: 100%;
    height: 100%;
  }
  @media ${device.allMobile} {
    width: 100%;
    height: 100%;
  }
  &:hover {
    // border: 2px solid ${Colors.secondaryColor};
  }
  & > a {
    margin: 0;
    color: ${Colors.black};
    &:hover {
      border-bottom: none;
    }
  }
  .card {
    &__img-wrapper {
      position: relative;
      max-height: 200px;
      border-radius: 10px 10px 0 0;
      @media ${device.laptop} {
        max-height: 150px;
      }
      & > span,
      img {
        width: 100%;
        height: 200px;
        position: relative;
        display: inline-block;
        background: ${Colors.grey};
        border-radius: 10px 10px 0 0;
        object-fit: cover;
        @media ${device.laptop} {
          max-height: 150px;
        }
        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
    &__category {
      color: ${Colors.white};
      text-transform: uppercase;
      font-size: 12px;
      background-color: rgba(42, 42, 42, 0.8);
      padding: 5px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      opacity: 0.8;
    }
    &__form {
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
      display: flex;
      align-items: center;
      color: ${Colors.black};
      text-transform: uppercase;
      font-size: 12px;
      background: ${Colors.white};
      padding: 5px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
      opacity: 0.5;
      @media ${device.laptop} {
        font-size: 10px;
      }
    }
    &__content {
      padding: 10px 20px;
      @media (max-width: 430px) {
        padding: 10px 10px;
      }
      footer {
        display: flex;
        justify-content: space-between;
        @media (max-width: 430px) {
          flex-direction: column;
        }
        p {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 1;
          margin-top: 5px;
          @media ${device.laptop} {
            font-size: 12px;
          }
          span {
            margin-right: 5px;
          }
        }
      }
    }
    &__name {
      margin: 0;
      font-size: 18px;
      margin-bottom: 10px;
      font-weight: bold;
      height: 90px;
      overflow-y: hidden;
      text-align: left;
      @media ${device.laptop} {
        font-size: 16px;
      }
    }
    &__description {
      font-size: 16px;
      margin: 0;
      width: 100%;
      min-height: 100px;
      overflow-y: hidden;
      overflow-x: hidden;
      margin-bottom: 90px;
      @media ${device.laptop} {
        font-size: 14px;
        min-height: 75px;
      }
    }
  }
  .footer {
    position: relative;
    // bottom: 5px;
    left: 0;
    width: 100%;
    // padding: 0 20px;
    p {
      font-weight: bold;
    }
  }

  .limit {
    word-wrap: break-word;
  }
`;

export default function Card(props) {
  const {
    project: {
      category,
      name,
      description,
      target,
      fundings,
      days,
      url,
      from,
      photo,
      is_patreon,
      is_ticketable,
      donations_count,
      id,
      status,
    },
    isSuccessful,
  } = props;
  const percentage = Math.round((fundings / target) * 100);
  return (
    <div className={'card'} style={{ height: isMobile() ? '100%' : 'auto' }}>
      <CardWrapper>
        <Link
          style={{ color: '#1e322b' }}
          to={url ? `/project/${url}` : '/project/iiiev'}
        >
          <div className="card__img-wrapper">
            <picture>
              <img src={photo} alt={name} />
            </picture>
            {/* {
                isMobile() ? (
                  <>
                    {mobile_photo ? (
                      <picture>
                        <img src={mobile_photo} alt={name} />
                      </picture>
                    ) : photo ? (
                      <picture>
                        <img src={photo} alt={name} />
                      </picture>
                    ) : (
                      <span>Projektbild</span>
                    )}
                  </>
                ) : (
                  <>
                    {photo ? (
                      <picture>
                        <img src={photo} alt={name} />
                      </picture>
                    ) : mobile_photo ? (
                      <picture>
                        <img src={mobile_photo} alt={name} />
                      </picture>
                    ) : (
                      <span>Projektbild</span>
                    )}
                  </>
                )
              } */}
            {category && <p className="card__category">{category}</p>}

            {/* <p className="card__form">
              <picture>
                <img src={SupporterIcon} alt="supporter-icon" />
              </picture>
              {from} Supporter
            </p> */}
          </div>
        </Link>
        <div className="card__content">
          <Link
            style={{ color: '#1e322b' }}
            to={url ? `/project/${url}` : '/project/iiiev'}
          >
            <h2 className="card__name limit truncate-3-lines " title={name}>
              {props.project.name}
            </h2>
            {/* <p title={description} className="card__description">
              {isMobile()
                ? description.length > 89
                  ? description.substring(0, 89)
                  : description.substring(0, 92)
                : isTablet()
                ? description.length > 89
                  ? description.substring(0, 89)
                  : description.substring(0, 92)
                : isLabTop()
                ? description.length > 105
                  ? description.substring(0, 100)
                  : description.substring(0, 107)
                : description.length > 118
                ? description.substring(0, 118)
                : description.substring(0, 120)}
              {((isMobile() && description.length > 92) ||
                (isTablet() && description.length > 92) ||
                (isLabTop() && description.length > 107) ||
                description.length > 120) &&
                '...'}
            </p> */}
          </Link>
          <div className="footer">
            {/* <Link
              style={{ color: '#1e322b' }}
              to={url ? `/project/${url}` : '/project/iiiev'}
            > */}
            <div
              style={{
                display: 'flex',
                marginBottom: 10,
                textAlign: 'left',
              }}
            >
              <div
                style={{ display: 'inline-flex', alignItems: 'center', gap: 6 }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 10.5V9.5C8.5 8.39543 7.60457 7.5 6.5 7.5H2.5C1.39543 7.5 0.5 8.39543 0.5 9.5V10.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.5 5.5C5.60457 5.5 6.5 4.60457 6.5 3.5C6.5 2.39543 5.60457 1.5 4.5 1.5C3.39543 1.5 2.5 2.39543 2.5 3.5C2.5 4.60457 3.39543 5.5 4.5 5.5Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11.5 10.5V9.5C11.4993 8.58856 10.8825 7.79286 10 7.565"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M8 1.565C8.88496 1.79159 9.50392 2.589 9.50392 3.5025C9.50392 4.41601 8.88496 5.21342 8 5.44"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>{' '}
                <p
                  style={{
                    fontSize: '14px',
                    display: 'inline',
                    fontWeight: '400',
                    margin: 0,
                  }}
                >
                  {' '}
                  {from}
                </p>
              </div>
              {/* {is_patreon === false && is_ticketable === false && ( */}
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: 6,
                }}
              >
                &nbsp;&nbsp;
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M6 3V6L7.5 7.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>{' '}
                <p
                  style={{
                    fontSize: '14px',
                    display: 'inline',
                    fontWeight: '400',
                    margin: 0,
                  }}
                >
                  {days} Tage verbleibend
                </p>
              </div>
              {/* )} */}
            </div>

            <ProgressBar percentage={percentage} />
            <footer style={{ marginTop: 10 }}>
              {is_patreon ? (
                <div className="icon_main_div_supporters">
                  <img
                    src={SupporterIcon}
                    style={{
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      marginTop: '5px',
                    }}
                    alt="supporter-icon"
                  />
                  <p>{Number(donations_count)} Supporter</p>
                </div>
              ) : is_ticketable ? (
                <div className="icon_main_div_supporters">
                  <img
                    src={SupporterIcon}
                    style={{
                      width: '15px',
                      minWidth: '15px',
                      height: '15px',
                      marginTop: '5px',
                    }}
                    alt="supporter-icon"
                  />
                  <p>{Number(donations_count)} Tickets verkauft</p>
                </div>
              ) : (
                <p className="card__target">
                  {/* <SvgWrapper width="14px" height="14px">
                    <TargetIcon />
                  </SvgWrapper> */}
                  <div>
                    <div
                      style={{
                        fontWeight: 'bolder',
                        fontSize: 20,
                        marginBottom: 5,
                        textAlign: 'left',
                      }}
                    >
                      {numberWithCommas(parseFloat(fundings).toFixed(2))} €
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        color: '#4e5562',
                        textAlign: 'left',
                      }}
                    >
                      finanziert von{' '}
                      {numberWithCommas(parseFloat(target).toFixed(2))} €
                    </div>
                  </div>
                  {/* (
                  {Math.round((fundings / target) * 100)}
                  %) */}
                </p>
              )}
              {days != 0 && (
                <>
                  {is_ticketable !== true && (
                    <footer>
                      {status !== 'finished' && !isSuccessful && (
                        <DonationButton id={id} url={url} name={name} />
                      )}
                    </footer>
                  )}
                </>
              )}
            </footer>
            {/* </Link> */}
          </div>
        </div>
      </CardWrapper>
    </div>
  );
}

Card.propTypes = {
  project: PropTypes.object.isRequired,
};
